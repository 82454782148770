import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { findProject , createContribution} from '../api'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Button from '../components/UI/Button'
import Loader from '../components/UI/Loader'
import NotFound from '../components/UI/NotFound'

const location = () => (typeof window !== `undefined`) ? window.location : { pathname: '' }
const match = (location().pathname.match(/contribute\/(\d+)_(.+)/) || [])
const projectId = match[1]
const token = match[2]

export default function Project() {
  const [project, setProject] = useState(null)
  useEffect(() => {
    findProject(projectId, token).then(e => setProject(e))
  }, [])
  if (!project) return <Loader />
  if (project.error) return <NotFound />
  return ( 
    <div className="flex flex-col justify-center items-center w-full mx-auto" style={{ maxWidth: 900 }}>
      <Header project={project} />
      <div className="text-left text-lg lg:text-xl font-light my-6 max-w-2xl">
        <ReactMarkdown>{project.information}</ReactMarkdown>
      </div>
      {project.status === 'closed' &&
        <div className="italic">Les participations sont fermées</div>
      }
      {project.status === 'open' &&
        <Button onClick={() => createContribution(projectId, token).then(contrib => window.location = `/contribution/${contrib.id}_${contrib.token}`)}>Ajouter ma contribution</Button>
      }
      <Footer />
    </div>
  )
}
