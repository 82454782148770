import React, { useState } from 'react'
import { FiLoader } from 'react-icons/fi'

export default function Button({ onClick, children, style, className, variant = 'default', Icon, disabled = false, forceLoading = false }) {
  const [loading, setLoading] = useState(false)
  const iconStyles = {
    default: 'stroke-1 mr-2 -ml-1',
    primary: 'mr-2 -ml-1',
    icon: 'stroke-1'
  }
  const styles = {
    default: `text-base font-normal py-2 px-6`,
    primary: `text-lg font-normal py-3 px-8`,
    secondary: `text-xs font-light py-1 px-3`,
    icon: `h-8 w-8 font-light py-1 px-1 box-content`
  }
  return (
    <button
      onClick={async () => {
        if (disabled) return
        setLoading(true)
        try {
          await onClick()
        } catch (error) {
          setLoading(false)
        }
        setLoading(false)
      }}
      disabled={disabled || loading || forceLoading}
      style={style}
      className={`relative overflow-hidden flex justify-center items-center align-middle focus:outline-none select-none flex-shrink-0 text-white bg-blue transition-all duration-100 hover:bg-blue-dark rounded shadow ${styles[variant]} ${className} ${disabled && 'bg-gray-200 hover:bg-gray-200'}`}>
      {Icon && <Icon className={iconStyles[variant]} />}
      {children}
      {(loading || forceLoading) &&
        <div className={`absolute w-full h-full p-1 bg-gray-200`}>
          <FiLoader className="stroke-1 rotating box-border w-full h-full" />
        </div>
      }
    </button>
  )
}
